<template>
  <LayoutParametrage title="Partenaires">
    <template #current_page>
      <span v-if="$route.name != 'update-partenaire'">
        Ajouter un partenaire
      </span>
      <span v-else>Modifier le partenaire</span>
    </template>
    <template #add_button>
      <router-link
        class="text-promy-green-300 cursor-pointer"
        to="/partenaires/promy"
      >
        &larr; retour
      </router-link>
    </template>
    <template #modal>
      <span
        class="mb-11 text-promy-gray-650 text-promy-xl font-extrabold font-main"
        >Information :</span
      >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form_ref">
        <div class="form__types_field_layout">
          <div class="space-y-4">
            <div class="ml-2 space-x-8">
              <Checkbox
                v-model="is_regional"
                :val="is_regional"
                id="IsRegional"
              />
              <span>Régional</span>
            </div>
            <div
              class="form__types_field_group"
              :class="{ 'w-64 animate-pulse': !is_regions_loaded }"
            >
              <div class="form__types_field_items">
                <div
                  v-for="region in regions"
                  :key="`region_${region.id}`"
                  class="space-x-8"
                >
                  <Checkbox
                    v-model="region.is_checked"
                    :val="region.is_checked"
                    :id="`region_${region.id}`"
                    :disabled="!is_regional"
                    :class="{ disabled: !is_regional }"
                  />
                  <span>{{ region.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="space-y-4">
            <div class="ml-2 space-x-8">
              <Checkbox
                v-model="is_departemntal"
                :val="is_departemntal"
                id="IsDepartemental"
              />
              <span>Départemental</span>
            </div>
            <div
              class="form__types_field_group"
              :class="{ 'w-64 animate-pulse': !is_departments_loaded }"
            >
              <div class="form__types_field_items">
                <div
                  v-for="department in departments.filter(
                    (departement) => !departement.is_hidden,
                  )"
                  :key="`department_${department.id}`"
                  class="space-x-8"
                >
                  <Checkbox
                    v-model="department.is_checked"
                    :val="department.is_checked"
                    :id="`department_${department.id}`"
                    :disabled="!is_departemntal"
                    :class="{ disabled: !is_departemntal }"
                  />
                  <span>{{ department.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="space-x-8">
            <Checkbox
              v-model="is_national"
              :val="is_national"
              id="IsNational"
            />
            <span>National</span>
          </div>
        </div>
        <div class="form__info_fields_layout">
          <div>
            <div class="sm:w-full">
              <div class="form__info_fields_group">
                <vue-select
                  :data="
                    secteur_activite_choices.map(
                      (secteur_activite) => secteur_activite.name,
                    )
                  "
                  v-model="secteur_activite"
                  class="shadow-none mt-2"
                  @valueSelect="
                    (value) => {
                      secteur_activite = value
                    }
                  "
                >
                  <template v-slot:SelectLabel>Secteur d'activité</template>
                </vue-select>
                <pro-input
                  name="nom"
                  requirements="required"
                  v-model="nom"
                  label="Nom partenaire"
                />
                <pro-input
                  name="adresse"
                  requirements="required"
                  v-model="adresse"
                  label="Adresse"
                />
                <InputPhoneNumber
                  name="Téléphone"
                  :rules="'required'"
                  v-model="phone_number"
                  placeholder="+33"
                  label="Téléphone"
                />
                <pro-input
                  name="Complément d'adresse"
                  v-model="adress_complement"
                  label="Complément d'adresse"
                />
                <pro-input
                  name="interlocuteur"
                  requirements="required"
                  v-model="interlocuteur"
                  label="Interlocuteur"
                />
                <pro-input
                  name="Code postal"
                  requirements="required"
                  v-model="postal_code"
                  label="Code postal"
                />
                <pro-input
                  name="email"
                  requirements="required"
                  v-model="email"
                  label="Email"
                />
                <pro-input
                  name="Ville"
                  requirements="required"
                  v-model="city"
                  label="Ville"
                />
                <pro-input
                  name="url"
                  requirements="website"
                  v-model="url"
                  label="URL"
                />
              </div>
            </div>
            <div class="relative md:w-full h-64 mt-4">
              <label class="form__info_field_label">Description :</label>
              <quill-editor
                class="mt-2 h-full"
                ref="myQuillEditor"
                v-model="description"
              />
            </div>
            <div class="form__slugs_field_container">
              <slugs-input
                ref="promySlugs"
                v-model="plus_by_promy"
                :slugs="plus_by_promy"
                label="Le + by Promy: "
              />
            </div>
          </div>
          <div class="sm:mt-6 w-1/3 sm:w-full">
            <label class="form__info_field_label">Logo :</label>
            <InputImage
              :file="logo"
              :selfUploader="!!logo"
              isFullPath
              @upload="(file) => (logo = file)"
              @deleteImage="() => (logo = null)"
            />
          </div>
        </div>
        <div class="mt-5">
          <div class="grid grid-cols-2 gap-x-6">
            <InputVideo
              @deleteVideo="deletedVideo"
              @videoChange="appendVideos"
              ref="promyVids"
              :videos="videos"
              label="Video Youtube: "
              :shouldValidate="shouldValidate"
            />
            <div>
              <FileField
                :shouldValidate="shouldValidate"
                @downloadFile="downloadFile"
                @showFile="showFile"
                @deleteFile="deletedFile"
                @FileChange="appendFiles"
                ref="promyVids"
                v-model="files"
                :files="files"
                label="Pdf:"
              />
            </div>
          </div>
        </div>
        <div class="w-full my-8" v-if="$can('edite,partenaire')">
          <button
            v-if="$route.name === 'update-partenaire'"
            class="form__update_cta btn-green"
            @click="update(handleSubmit)"
          >
            Modifier
          </button>
          <button
            v-else
            class="form__add_cta btn-green"
            @click="add(handleSubmit)"
          >
            Ajouter
          </button>
        </div>
      </ValidationObserver>
    </template>
  </LayoutParametrage>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      // Regions related data
      is_regional: false,
      was_regional: false,
      was_regional_updated: false,
      regions: [],
      is_regions_loaded: false,

      // Departments related data
      is_departemntal: false,
      was_departemntal: false,
      was_departemntal_updated: false,
      departments: [],
      is_departments_loaded: false,

      // Nations related data
      is_national: false,
      nations: [],

      // Activite sector related data
      secteur_activite: '',
      secteur_activite_choices: [],

      // Other data
      nom: '',
      email: '',
      interlocuteur: '',
      adresse: '',
      url: '',
      description: '',
      email: '',
      phone_number: '',
      logo: '',
      plus_by_promy: [],
      videos: [],
      deletedVideos: [],
      files: [],
      deletedFiles: [],
      adress_complement: '',
      postal_code: '',
      city: '',
      shouldValidate: false,
    }
  },

  created() {
    // Route name states :
    // - add-partenaire
    // - update-partenaire

    this.getAbonnementTypes()
    this.getSecteursActivite()

    if (this.$route.name === 'update-partenaire')
      this.$store.dispatch('partenaire/getPartenaire', this.$route.params.id)
  },

  watch: {
    curren_partenaire: {
      deep: true,
      immediate: true,
      handler(value) {
        value && this.fillForm(value)
      },
    },

    regions: {
      deep: true,
      immediate: true,
      handler(value) {
        value.length && (this.is_regions_loaded = this.syncRegions(value))
      },
    },

    departments: {
      deep: true,
      immediate: true,
      handler(value) {
        value.length &&
          (this.is_departments_loaded = this.syncDepartments(value))
      },
    },

    is_departemntal(isIt) {
      if (isIt) this.is_national = !isIt
      this.was_departemntal = this.is_national ? this.was_departemntal : isIt
    },

    is_regional(isIt) {
      if (isIt) this.is_national = !isIt
      else if (!isIt && !this.is_national) this.checkRegions((region) => false)
      this.was_regional = this.is_national ? this.was_regional : isIt
    },

    is_national(isIt) {
      this.is_regional = !isIt && this.was_regional
      this.is_departemntal = !isIt && this.was_departemntal
      this.nations = isIt ? [{ id: 1 }] : []
    },
  },

  methods: {
    downloadFile(file) {
      this.$http
        .get(`partenaires/${this.$route.params.id}/files/${file.id}/download`, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${file.name}.pdf`
          link.click()
        })
    },
    showFile(file) {
      window.open(
        `${process.env.VUE_APP_BASE_URL}partenaires/${this.$route.params.id}/files/${file}/show`,
        '_blank',
      )
    },
    deletedVideo(value) {
      this.deletedVideos.push(value)
    },
    deletedFile(value) {
      this.deletedFiles.push(value)
    },
    appendFiles(value) {
      this.files.push(value)
    },
    appendVideos(value) {
      this.videos.push(value)
    },
    checkChanges(old_data, new_data) {
      return (
        JSON.stringify([...new Set(old_data)].sort()) !==
        JSON.stringify([...new Set(new_data)].sort())
      )
    },

    setActivitySector(sector_id) {
      this.secteur_activite = this.secteur_activite_choices.filter(
        (sector) => sector.id === sector_id,
      )[0].name
    },

    fillForm(value) {
      this.files = value.files
      this.videos = value.videos
      this.is_national = value.nations && value.nations.length
      this.is_regional = value.regions && value.regions.length
      this.is_departemntal = value.departements && value.departements.length

      this.secteur_activite_choices.length &&
        this.setActivitySector(value.secteur_activite_id)

      this.interlocuteur = value.interlocuteur
      this.plus_by_promy = value.plus_by_promy
      this.nom = value.nom
      this.url = value.url
      this.description = value.description
      this.email = value.email
      this.phone_number = value.phone_number
      this.logo = value.logo_path
      this.adresse = value.adresse
      this.adress_complement = value.adress_complement
      this.postal_code = value.postal_code
      this.city = value.city
    },

    checkRegions(condition) {
      this.regions = this.regions.map((region) => ({
        ...region,
        is_checked: condition(region),
      }))
    },

    updateDepartmentsDependingOnRegions(depended_regions, check_condition) {
      const updatedDepartments = this.departments.map((department) => ({
        ...department,
        is_hidden: depended_regions.some(
          (region) => region.id === department.region_id && region.is_checked,
        ),
        is_checked: check_condition(
          department,
          depended_regions.find((region) => region.id === department.region_id),
        ),
      }))

      if (this.checkChanges(this.departments, updatedDepartments))
        this.departments = updatedDepartments

      return true
    },

    updateRegionsDependingOnDepartments(depended_departments) {
      const updatedRegions = this.regions.map((region) =>
        depended_departments.some(
          (department) =>
            department.region_id === region.id && !department.is_checked,
        )
          ? region
          : { ...region, is_checked: true },
      )

      this.is_regional = this.regions.some((region) => region.is_checked)

      if (this.checkChanges(updatedRegions, this.regions))
        this.regions = updatedRegions

      return true
    },

    updateRegionsWithCurrentPartenaire() {
      this.checkRegions((region) =>
        this.curren_partenaire.regions.includes(region.id),
      )
      this.was_regional_updated = !this.was_regional_updated
      return true
    },

    updateDepartmentsWithCurrentPartenaire() {
      this.was_departemntal_updated = this.updateDepartmentsDependingOnRegions(
        this.regions,
        (department, region) =>
          this.curren_partenaire.departements.includes(department.id),
      )

      return true
    },

    syncRegions(value) {
      return this.curren_partenaire && !this.was_regional_updated
        ? this.updateRegionsWithCurrentPartenaire()
        : this.updateDepartmentsDependingOnRegions(
            value,
            (department, region) => department.is_checked && !region.is_checked,
          )
    },

    syncDepartments(value) {
      return this.curren_partenaire && !this.was_departemntal_updated
        ? this.updateDepartmentsWithCurrentPartenaire()
        : this.updateRegionsDependingOnDepartments(value)
    },

    getAbonnementTypes() {
      this.$http.get('regions').then((res) => {
        this.regions = res.data.regions.map((region) => ({
          id: region.id,
          name: region.name,
          is_checked: false,
        }))
      })

      this.$http.get('departements').then((res) => {
        this.departments = res.data.departements.map((department) => ({
          id: department.id,
          region_id: department.region_id,
          name: department.name,
          is_checked: false,
          is_hidden: false,
        }))
      })
    },

    getSecteursActivite() {
      this.$http.get('admin/activity-sectors-list').then((res) => {
        this.secteur_activite_choices = res.data.map((secteur_activite) => ({
          id: secteur_activite.id,
          name: secteur_activite.name,
        }))

        if (this.$route.name === 'add-partenaire')
          this.secteur_activite = this.secteur_activite_choices[0].name

        this.curren_partenaire &&
          this.setActivitySector(this.curren_partenaire.secteur_activite_id)
      })
    },

    validateTypes() {
      const validateTypesCondition =
        !this.is_national && !this.is_regional && !this.is_departemntal

      if (validateTypesCondition)
        this.$toaster.warning(
          'Veuillez sélectionner au moins une région ou un département !',
        )

      return !validateTypesCondition
    },

    prepareFormData(method) {
      let data = new FormData()

      data.append('nom', this.nom)
      data.append('url', this.url)

      this.files.forEach((file, index) => {
        if (file.file.type && file.file.type == 'application/pdf') {
          data.append(`documents[${index}][file]`, file.file)
          data.append(`documents[${index}][name]`, file.name)
        }
      })

      this.deletedFiles.forEach((file) => {
        data.append('deletedFiles[]', file)
      })

      this.videos.forEach((video, index) => {
        if (video.id) return
        data.append(`videos[${index}][name]`, video.name)
        data.append(`videos[${index}][url]`, video.url)
      })

      this.deletedVideos.forEach((video) => {
        data.append('deletedVideos[]', video)
      })

      !this.is_national &&
      this.departments.filter((department) => department.is_checked).length
        ? this.departments
            .filter((department) => department.is_checked)
            .forEach((department) =>
              data.append('departements[]', department.id),
            )
        : data.append('departements[]', [])

      !this.is_national &&
      this.regions.filter((region) => region.is_checked).length
        ? this.regions
            .filter((region) => region.is_checked)
            .forEach((region) => data.append('regions[]', region.id))
        : data.append('regions[]', [])

      this.is_national
        ? this.nations.forEach((nation) => data.append('nations[]', nation.id))
        : data.append('nations[]', [])

      data.append('logo', this.logo)
      data.append('description', this.description)
      data.append('interlocuteur', this.interlocuteur)
      data.append('email', this.email)
      data.append('phone_number', this.phone_number)
      data.append(
        'secteur_activite_id',
        this.secteur_activite_choices.find(
          (secteur) => secteur.name === this.secteur_activite,
        ).id,
      )
      if (this.$refs.promySlugs.newSlug) {
        this.plus_by_promy.push(this.$refs.promySlugs.newSlug)
        this.$refs.promySlugs.newSlug = ''
      }

      this.plus_by_promy.length
        ? this.plus_by_promy.forEach((plus_by_promy) =>
            data.append('plus_by_promy[]', plus_by_promy),
          )
        : data.append('plus_by_promy', [])

      data.append('adresse', this.adresse)
      data.append('adress_complement', this.adress_complement)
      data.append('postal_code', this.postal_code)
      data.append('city', this.city)

      if (method === 'PUT') data.append('_method', 'PUT')

      return data
    },
    async validationInput() {
      this.shouldValidate = true
    },
    async add(handleSubmit) {
      await this.validationInput()
      let isFormValid = await this.$refs.form_ref.validate()
      if (!this.validateTypes() || !isFormValid) return

      let res = await this.$store.dispatch(
        'partenaire/addPartenaire',
        this.prepareFormData('POST'),
      )
      if (res === 'Ok') {
        this.$toaster.success('Le partenaire est bien été ajouté')
        this.$router.push('/partenaires/promy')
      } else {
        this.$toaster.warning(
          (res.response.status === 422 &&
            `Les données fournies étaient invalides pour : ${Object.keys(
              res.response.data.errors,
            )
              .join(', ')
              .replace(/phone_number/, 'téléphone')} .`) ||
            'Tous les champs sont obligatoires!',
        )
      }
    },

    async update(handleSubmit) {
      await this.validationInput()

      let isFormValid = await this.$refs.form_ref.validate()

      if (!this.validateTypes() || !isFormValid) return

      let res = await this.$store.dispatch('partenaire/updatePartenaire', {
        data: this.prepareFormData('PUT'),
        id: this.curren_partenaire.id,
      })

      if (res === 'Ok') {
        this.$toaster.success('Le partenaire est bien été modifié')
        this.$router.push('/partenaires/promy')
      } else {
        this.$toaster.warning(
          (res.response.status === 422 &&
            `Les données fournies étaient invalides pour : ${Object.keys(
              res.response.data.errors,
            ).join(', ')} .`) ||
            'Tous les champs sont obligatoires!',
        )
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit('partenaire/SET_CURRENT_PARTENAIRE', null)
    next()
  },

  computed: {
    ...mapGetters({
      curren_partenaire: 'partenaire/currentPartenaire',
    }),
  },
}
</script>

<style>
.form__types_field_layout {
  @apply mb-12 flex md:grid md:grid-cols-2 sm:grid-cols-1 md:gap-x-4 md:gap-y-8 space-x-12 md:space-x-0;
}

.form__types_field_group {
  @apply py-8 pl-7 pr-5 bg-[#F0F0F0] rounded-xl;
}

.form__types_field_items {
  @apply h-56 pr-16 space-y-4 overflow-y-auto;
  scroll-behavior: smooth;
}

.form__info_fields_layout {
  @apply flex space-x-24 lg:space-x-8 md:space-x-0 md:flex-col md:space-y-8;
}

.form__info_fields_group {
  @apply grid grid-cols-2 gap-x-4 sm:grid-cols-1 sm:gap-x-0 sm:gap-y-4;
}

.form__info_field_label {
  @apply capitalize text-xs text-gray-600 font-bold font-arial;
}

.form__slugs_field_container {
  @apply relative w-2/3 sm:w-full lg:mt-30 mt-28 sm:mt-34;
}

.form__update_cta {
  @apply px-6 py-2 sm:w-full mr-4;
}

.form__delete_cta {
  @apply px-6 py-2 sm:w-full sm:mt-4 hover:bg-promy-red hover:border-promy-red;
}

.form__add_cta {
  @apply px-6 py-2 sm:w-full md:mt-8;
}

.form__remove_confirm_message {
  @apply text-promy-gray-700 text-sm font-arial py-4;
}

.form__remove_confirm_canceler {
  @apply px-6 py-2 sm:w-full md:mt-8 mr-4;
}

.form__remove_confirm_approver {
  @apply px-6 py-2 sm:w-full md:mt-8 hover:bg-promy-red hover:border-promy-red;
}
</style>
